export const LIBUM_URL = 'https://libum.io';
export const LIBUM_KEYWORDS = 'Libum,PowerOn Pro,NexumAPI,Glaci,Jack Henry software,Symitar software,credit union software,';
export const LIBUM_IMAGE_PARTIAL_URL = 'https://libum-media.s3.us-east-2.amazonaws.com/1200x630_social_card'; // requires _{socialCard}.png

export const LIBUM_API_NEXUM_DELTA = 'https://api.libum.io/nexum/changelog';
export const LIBUM_API_NEXUM = 'https://docs.libum.io/nexum/symxchange/api-reference';
export const LIBUM_DOCS_GLACI = 'https://docs.libum.io/glaci';
export const LIBUM_DOCS_NEXUM = 'https://docs.libum.io/nexum';
export const LIBUM_DOCS_POWERON_PRO = 'https://docs.libum.io/poweron-pro';
export const LIBUM_FEEDBACK = 'https://feedback.libum.io';
export const LIBUM_STATUS = 'https://status.libum.io';
export const LIBUM_PORTAL = 'https://portal.libum.io';
export const LIBUM_POWERON_PRO_VSCODE_URL = 'https://marketplace.visualstudio.com/items?itemName=libum.poweron-pro';
export const LIBUM_SCHEDULING_DEMO_DYLAN = 'https://savvycal.com/martinezdylan/d6959952';

export const LINKEDIN_API_URL = 'https://corporate-website.libum.io;'
export const LINKEDIN_LKG = 398; // Last known static follower count

export const UNAVAILABLE_LINKS = [{
  href: LIBUM_DOCS_GLACI,
  title: 'Glaci Docs are not yet available!',
}, {
  href: LIBUM_STATUS,
  title: 'API status is not yet available!',
}, {
  href: LIBUM_API_NEXUM_DELTA,
  title: 'API changelog not yet available!',
}];

export const POWERON_PRO_INSTALL_URL = 'vscode:extension/libum.poweron-pro';
export const GLACI_VIDEO_ID = '02YoRWsVvbUjnL5pc3AUc01TlTxYQ6vv02Q2D6rHbwBDpg';

export const DISCORD_URL = 'https://discord.gg/libum';
export const GITHUB_URL = 'https://github.com/libum-llc';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/libum';
export const VSCODE_URL = 'https://code.visualstudio.com/';

export const JH_VENDORS_URL = 'https://www.jackhenry.com/for-vendors';
export const JH_CORE_PLATFORM_URL = 'https://www.jackhenry.com/what-we-offer/operations/core-platforms/credit-union-core-platform';

export const CUBUILD_URL = 'https://www.cubuild.org/';
export const SYMCENTRAL_2023_CONFERENCE_URL = 'https://web.cvent.com/event/6b494aa9-b584-458d-b4c6-2938011c7690/summary';

export const BLOCK_URL = 'https://block.xyz/';
export const ROKU_URL = 'https://www.roku.com/';
export const SANDIA_NATIONAL_LABS_URL = 'https://www.sandia.gov/';
export const NORTHROP_GRUMMAN_URL = 'https://www.northropgrumman.com/';
export const MICROSOFT_URL = 'https://www.microsoft.com/';
export const UNIVERSITY_OF_MICHIGAN_URL = 'https://umich.edu/';
export const UNIVERSITY_OF_HOUSTON_URL = 'https://www.uh.edu/';
export const BIG_TECH_URL = 'https://en.wikipedia.org/wiki/Big_Tech';
export const NAT_SEC_URL = 'https://en.wikipedia.org/wiki/National_security';

export const DYLAN_MAILTO = 'mailto:dylan@libum.io';
export const DYLAN_ICHTHUS = 'https://www.youtube.com/shorts/QziEpBDQayI';
export const DYLAN_LINKEDIN = 'https://www.linkedin.com/in/martinezdylanj/';
export const DYLAN_GITHUB = 'https://github.com/martinezdylan';

export const DON_MAILTO = 'mailto:don@libum.io';
export const DON_LINKEDIN = 'https://www.linkedin.com/in/nguyenbdon/';
export const DON_GITHUB = 'https://github.com/nguyendon';

export const RAY_LINKEDIN = 'https://www.linkedin.com/in/raysatterfield/';
export const MIKE_LINKEDIN = 'https://www.linkedin.com/in/mikewblumenthal/';

export const BILLING_MAILTO = 'mailto:billing@libum.io';
export const DEVELOPMENT_MAILTO = 'mailto:development@libum.io';
export const LEGAL_MAILTO = 'mailto:legal@libum.io';
export const PRIVACY_MAILTO = 'mailto:privacy@libum.io';
export const SALES_MAILTO = 'mailto:sales@libum.io';
export const SUPPORT_MAILTO = 'mailto:support@libum.io';
