import styled from "styled-components";

/* Devices */

const size = {
  miniMobile: '320px',
  mobile: '479px',
  tabletS: '620px',
  tablet: '768px',
  tabletL: '991px',
  laptopS: '1024px',
  laptopM: '1280px',
  laptopL: '1440px',
}

export const devices = {
  miniMobileMin: `screen and (min-width: ${size.miniMobile})`,
  miniMobileMax: `screen and (max-width: ${size.miniMobile})`,
  mobileMin: `screen and (min-width: ${size.mobile})`,
  mobileMax: `screen and (max-width: ${size.mobile})`,
  tabletSMin: `screen and (min-width: ${size.tabletS})`,
  tabletSMax: `screen and (max-width: ${size.tabletS})`,
  tabletMin: `screen and (min-width: ${size.tablet})`,
  tabletMax: `screen and (max-width: ${size.tablet})`,
  tabletLMin: `screen and (min-width: ${size.tabletL})`,
  tabletLMax: `screen and (max-width: ${size.tabletL})`,
  laptopSMin: `screen and (min-width: ${size.laptopS})`,
  laptopSMax: `screen and (max-width: ${size.laptopS})`,
  laptopMMin: `screen and (min-width: ${size.laptopM})`,
  laptopMMax: `screen and (max-width: ${size.laptopM})`,
  laptopLMin: `screen and (min-width: ${size.laptopL})`,
  laptopLMax: `screen and (max-width: ${size.laptopL})`,
}

/* Extras */

export const Sup = styled.sup`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.textTertiary};
`;

/* Links */

export const PrimaryLink = styled.a`
  display: inline-block;
  padding-left: 16px;
  padding-right: 12px;
  color: #fff;
  background-color: ${(props) => props.theme.colors.bgColor};
  border-radius: 16px;
  font-size: 15px;
  font-weight: 700;
  line-height: 33px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  svg.hover_arrow_svg__hover-arrow {
    fill: transparent;
  }
`;

export const ContentPanel = styled.div`
  padding: 24px 0;

  h1 {
    color: ${({ theme }) => theme.colors.textEmphasis};
    font-size: 40px;
    font-family: ${({ theme }) => theme.fontFamily.headline};
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 48px;
  }

  h3 {
    color: ${({ theme }) => theme.colors.textEmphasis};
    font-size: 24px;
    font-family: ${({ theme }) => theme.fontFamily.headline};
    font-weight: 500;
    line-height: 20px;
  }

  a {
    color: ${({ theme }) => theme.colors.textTertiary};
    transition: color 0.2s, fill 0.2s;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-top: 24px;
  }

  &:nth-of-type(2) {
    border-top: 1px solid ${(props) => props.theme.colors.border};
  }
`;

export const ContentHeadline = styled.div`
  margin: auto;
  max-width: 1080px;
  padding-inline: 16px;

  a.crumb {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
  }

  h1 {
    margin-top: 8px;
    margin-bottom: 6px;
  }

  p {
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.textSecondary};
    line-height: 24px;
  }

  @media ${devices.laptopSMax} {
    max-width: 840px;
  }
`;

export const ContentMain = styled(ContentPanel)`
  margin: auto;
  max-width: 1080px;
  padding-inline: 16px;

  h3 {
    font-family: ${({ theme }) => theme.fontFamily.display};
    font-weight: 500;
  }

  p {
    margin-top: 6px;
    color: ${({ theme }) => theme.colors.textPrimary};
    line-height: 24px;
  }

  @media ${devices.laptopSMax} {
    max-width: 840px;
  }
`;

export const ContentList = styled.ul`
  maring-top: 12px;
  padding-left: 0;
  list-style-type: none;
`;

export const ContentListItem = styled.li`
  position: relative;
  margin-top: 0;
  margin-bottom: 36px;
  padding: 0;

  h3 {
    width: fit-content;
    line-height: 1.1;

    a {
      display: flex;
      align-items: center;
      flex: 0 0;
      gap: 4px;

      svg {
        fill: ${({ theme }) => theme.colors.textTertiary};
        width: 18px;
        height: 18px;
      }
    }
  }
`;

export const ContentListItemMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  margin: 10px 0 0;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textSecondary};

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    fill: ${({ theme }) => theme.colors.textSecondary};
    color: ${({ theme }) => theme.colors.textSecondary};

    svg {
      width: 20px;
      height: 20px;
    }
  }

  a:hover {
    fill: ${({ theme }) => theme.colors.textTertiary};
    color: ${({ theme }) => theme.colors.textTertiary};
    text-decoration: none;
  }
`;
